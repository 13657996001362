import React, { useContext } from 'react'
import LibraryWrapper from './LibraryWrapper'
import { ImpactContext } from '../../components/Layout';
import { Entity } from '@parallelpublicworks/entitysync/';
import { 
    InputDate
} from '../../components/library/entitysync'; 


function inputSelect() {
    const {user} = useContext(ImpactContext)
    return (
        <LibraryWrapper>
            <Entity componentId="user-entity" type="user--user" source={user}>
                <InputDate
                    field="input_date" 
                    label="Select Date"
                />
            </Entity>
        </LibraryWrapper>
    )
}
export default inputSelect
